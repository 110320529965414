import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Ebay from "../images/ebay.png"
import DemoFooter from "../components/demoFooter"

export default function(){
  return (
    <Layout>
      <SEO title={"eBay Monthly Savings Calculator"}
           description={"eBay VIP is an opt-in feature that requires approval from eBay Corporate. Benefits include the following for all PSS dealers that have an eBay seller rating of “standard” or higher. Power Sellers still keep their discounts!"}/>

      <div className={"row justify-content-center my-5"}>
        <div className={"col-md-6 order-2 order-md-1"}>
          <p className={"lead"}>
            eBay VIP is an opt-in feature that requires approval from eBay Corporate. Benefits include the following for all PSS dealers that have an eBay seller rating of “standard” or higher. Power Sellers still keep their discounts!
          </p>
          <ul>
            <li>Free Anchor Store ($350/mo savings)</li>
            <li>Zero Insertion Fees (Unlimited listings)</li>
            <li>Free Subtitles (Unlimited listings)</li>
            <li>Account Manager at eBay</li>
          </ul>
        </div>
        <div className={"col-md-3 align-self-center order-1 order-md-2 mb-2"}>
          <img src={Ebay} alt={"Ebay Logo"} className={"img-fluid"}/>
        </div>
      </div>


      <div className={"row justify-content-center"}>
        <div className={"col-10"}>
          <h3>Projected Monthly Savings</h3>
          <p className={"small text-muted"}>Assuming 50,000 monthly listings online.</p>
          <table className="table table-striped table-responsive-lg">
            <thead>
            <tr>
              <th>Store Tiers</th>
              <th>Store Fee</th>
              <th>Insertion Fees</th>
              <th>Free Insertions/Month</th>
              <th className="text-right">Projected Cost/Month</th>
              <th className="text-right">Projected Cost w/ Subtitles</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Basic</td>
              <td>$19.99/Month</td>
              <td>$0.20/Month</td>
              <td>First 50/Month</td>
              <td className="text-right" id="basic-cost">$10009.99</td>
              <td className="text-right" id="basic-subtitles">$85009.99</td>
            </tr>
            <tr>
              <td>Premium</td>
              <td>$49.99/Month</td>
              <td>$0.10/Listing</td>
              <td>First 250/Month</td>
              <td className="text-right" id="premium-cost">$5024.99</td>
              <td className="text-right" id="premium-subtitles">$80024.99</td>
            </tr>
            <tr>
              <td>Anchor</td>
              <td>$350.00/Month</td>
              <td>$0.05/Listing</td>
              <td>First 2500/Month</td>
              <td className="text-right" id="anchor-cost">$2725.00</td>
              <td className="text-right" id="anchor-subtitles">$77725.00</td>
            </tr>
            <tr className={'bg-success'}>
              <td>PSS VIP*</td>
              <td>$0.00/Month</td>
              <td>$0.00/Listing</td>
              <td>Unlimited</td>
              <td className="text-right" id="vip-cost">$0.00</td>
              <td className="text-right" id="vip-subtitles">$0.00</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className={"row justify-content-center mb-5"}>
        <div className={"col-10"}>
          <p className={"small text-muted mb-0"}>
            * Prices may vary and are subject to change
          </p>
          <p className={"small text-muted"}>
            * PSS VIP receives all of the benefits of an Ebay Anchor store PLUS those listed below in the PSS VIP Benefits section below.
          </p>
        </div>
      </div>

      <div className={"row justify-content-center mb-5"}>
        <div className={"col-10"}>
          <h3>PSS VIP Benefits</h3>
          <table className="table table-striped">
            <thead>
            <tr>
              <th/>
              <th>Ebay Direct</th>
              <th>PSS VIP</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th>Anchor Store</th>
              <td>$350.00/Month</td>
              <td>$0.00/Month</td>
            </tr>
            <tr>
              <th>Insertion Fees</th>
              <td>$0.05/Listing</td>
              <td>$0.00/Listing</td>
            </tr>
            <tr>
              <th>Subtitle Fees</th>
              <td>$1.50/Listing</td>
              <td>$0.00/Listing</td>
            </tr>
            <tr>
              <th>Ebay Account Management</th>
              <td>Not Available</td>
              <td>$0.00/Month</td>
            </tr>
            <tr>
              <th>Ebay Merchant Support</th>
              <td>Not Available</td>
              <td>$0.00/Month</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <DemoFooter/>
    </Layout>
  )
}
